var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.home ? _c("home-header", { attrs: { home: _vm.home } }) : _vm._e(),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("home-slider", {
            attrs: {
              "slider-header": _vm.home.programSliderHeader,
              slides: _vm.home.programSlider,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("home-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }